import React from "react";
//import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="copyright_wrap">
          <div className="content_wrap">
            <span className="copyright_text">
              DentalPuente © 2018 All Rights Reserved.
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
