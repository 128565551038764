/* Global FB */
import React from "react";
import { Link } from "react-router-dom";

class Navbar extends React.Component {
  render() {
    const current = this.props.current;

    let cssPortada = "Navbar_list_li";
    let cssAbout = "Navbar_list_li";
    let cssGaleria = "Navbar_list_li";
    let cssContacto = "Navbar_list_li";
    let cssAccount = "Navbar_list_li";

    if (current === "Portada") {
      cssPortada += " Navbar_current";
    }
    if (current === "Informacion") {
      cssAbout += " Navbar_current";
    }
    if (current === "Galeria") {
      cssGaleria += " Navbar_current";
    }
    if (current === "Contacto") {
      cssContacto += " Navbar_current";
    }
    if (current === "Account") {
      cssAccount += " Navbar_current";
    }

    return (
      <React.Fragment>
        <div className="content_wrap">
          <nav className="Navbar_container">
            <ul className="Navbar_list">
              <li className={cssPortada}>
                <Link to="/" onClick={this.handleClick}>
                  <span>Portada</span>
                </Link>
              </li>
              <li className={cssAbout}>
                <Link to="/About" onClick={this.handleClick}>
                  <span>Acerca de</span>
                </Link>
              </li>
              <li className={cssGaleria}>
                <Link to="/Galeria" onClick={this.handleClick}>
                  <span>Galeria</span>
                </Link>
              </li>
              <li className={cssContacto}>
                <Link to="/Contacto" onClick={this.handleClick}>
                  <span>Contacto</span>
                </Link>
              </li>

              <li className={cssAccount}>
                <Link to="/Account">
                  <span>Tu Cuenta</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}

export default Navbar;
