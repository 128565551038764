import React from "react";
//import { Link } from "react-router-dom";

import Logo from "../../images/logo.png";
import ReactFBLike from "react-fb-like";

class Header extends React.Component {
  handleClick = async (e) => {
    try {
      const FB = window.FB;
      FB.init({
        appId: "1983706318578845", //random app number
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: "v8.0",
      });
      //JS SDK initialized, now you can use it
      FB.XFBML.parse();
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    return (
      <React.Fragment>
        <header>
          <div className="content_wrap">
            <header className="row header">
              <div className="col-header-left">
                <div>
                  <strong>Previa Cita </strong>
                  <span className="telefono_cita">33 34 75 81 91</span>
                </div>
                <div className="direccion_cita">
                  Teodomiro Manzano 4365, Guadalajara, Jal.
                </div>
              </div>
              <div className="col-header-logo">
                <img src={Logo} alt="Logo DentalPuente" className="LogoImage" />
              </div>
              <div className="col-header-right">
                <div className="horario_label">Horario:</div>
                <div className="horario_consulta">
                  Lun - Sab: <strong>10am - 7pm</strong> Dom: Cerrado
                </div>
                <div>
                  <ReactFBLike
                    language="es_LA"
                    appId="1983706318578845"
                    version="v8.0"
                    href="https://www.facebook.com/DentalPuente-2072424749721597"
                  />
                </div>
              </div>
            </header>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
