import React from "react";
//import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import fotoFaby from "../images/foto-faby.jpg";
import fotoMons from "../images/foto-monse.jpg";
import Navbar from "../Skin/Denti/Navbar";
import { Helmet } from "react-helmet";

class About extends React.Component {
  state = {
    current: "Informacion",
  };

  render() {
    //const faPhone = <FontAwesomeIcon icon={faPhone} />;
    const address_icon = <FontAwesomeIcon icon={faAddressBook} />;
    const phone_icon = <FontAwesomeIcon icon={faPhone} />;
    const envelope_icon = <FontAwesomeIcon icon={faEnvelope} />;

    return (
      <React.Fragment>
        <Helmet>
          <title>DentalPuente | Acerca de</title>
        </Helmet>

        <div className="hr-top clearfix hr-shadow">
          <Navbar current={this.state.current} />
        </div>
        <div className="content_wrap pbot352">
          <div className="row">
            <div className="col-3 photo_top">
              <img src={fotoFaby} alt="Info" className="dentistInfo_photo" />
            </div>
            <div className="col-9 dentistInfo">
              <h3>Dra. Ana Fabiola Puente Villafaña</h3>
              <h2>Cirujano Dentista y Especialista en Ortodoncia</h2>
              <div className="dentistContact">
                <p></p>

                <div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <span className="icon iconHex">{phone_icon}</span>
                    </div>
                    <div>
                      <h4>Teléfono</h4>
                      <span>Citas 33 34 75 81 91</span>
                    </div>
                  </div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <span className="icon iconHex">{envelope_icon}</span>
                    </div>
                    <div>
                      <h4>Email</h4>
                      <span>faby.puente@live.com</span>
                    </div>
                  </div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <span className="icon iconHex">{address_icon}</span>
                    </div>
                    <div>
                      <h4>Dirección</h4>
                      <span>Teodomiro Manzano 4365, Guadalajara, Jal.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hr-middle clearfix "></div>
          <div className="row">
            <div className="col-3 photo_top">
              <img src={fotoMons} alt="Info" className="dentistInfo_photo" />
            </div>
            <div className="col-9 dentistInfo">
              <h3>Dra. Montserrat Puente Villafaña</h3>
              <h2>Cirujano Dentista</h2>
              <div className="dentistContact">
                <p></p>

                <div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <span className="icon iconHex">{phone_icon}</span>
                    </div>
                    <div>
                      <h4>Teléfono</h4>
                      <span>Citas 3316369641</span>
                    </div>
                  </div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <span className="icon iconHex">{envelope_icon}</span>
                    </div>
                    <div>
                      <h4>Email</h4>
                      <span>montc.mcr@gmail.com</span>
                    </div>
                  </div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <span className="icon iconHex">{address_icon}</span>
                    </div>
                    <div>
                      <h4>Dirección</h4>
                      <span>Teodomiro Manzano 4365, Guadalajara, Jal.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
