import React from "react";
//import { Link } from "react-router-dom";

class NotFound extends React.Component {
  render() {
    return <React.Fragment>NotFound</React.Fragment>;
  }
}

export default NotFound;
