import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const center = {
  lat: 20.616792,
  lng: -103.375104,
};
const onLoad = (marker) => {
  console.log("marker: ", marker);
};

class GoogleMaps extends React.Component {
  render() {
    const style_width = this.props.style_width;
    const style_height = this.props.style_height;

    const containerStyle = {
      width: style_width,
      height: style_height,
    };

    return (
      <LoadScript googleMapsApiKey="AIzaSyAcXpX4lhh7qQTIq0slJxEdYDj5QaMIb8E">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18}>
          {/* Child components, such as markers, info windows, etc. */}
          <Marker onLoad={onLoad} position={center} />
        </GoogleMap>
      </LoadScript>
    );
  }
}

export default GoogleMaps;
