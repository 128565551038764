import React from "react";
//import { Link } from "react-router-dom";
//import { render } from "react-dom";
import { Helmet } from "react-helmet";
import Gallery from "react-photo-gallery";

import Navbar from "../Skin/Denti/Navbar";
import Breadcrumb from "../components/Breadcrumb";

import Consul1 from "../images/Gallery/consultorio-01.JPG";
import Consul2 from "../images/Gallery/consultorio-02.JPG";
import Consul3 from "../images/Gallery/consultorio-03.JPG";
import Consul4 from "../images/Gallery/consultorio-04.JPG";
import Consul5 from "../images/Gallery/consultorio-05.JPG";
import Consul6 from "../images/Gallery/consultorio-06.JPG";
import Consul7 from "../images/Gallery/consultorio-07.JPG";

class Galeria extends React.Component {
  state = {
    current: "Galeria",
  };

  render() {
    const photos = [
      {
        src: Consul1,
        width: 20,
        height: 20,
      },
      {
        src: Consul2,
        width: 10,
        height: 10,
      },
      {
        src: Consul3,
        width: 3,
        height: 4,
      },
      {
        src: Consul4,
        width: 3,
        height: 4,
      },
      {
        src: Consul5,
        width: 3,
        height: 4,
      },
      {
        src: Consul6,
        width: 4,
        height: 3,
      },
      {
        src: Consul7,
        width: 3,
        height: 4,
      },
    ];

    return (
      <React.Fragment>
        <Helmet>
          <title>DentalPuente | Galeria de fotos</title>
        </Helmet>

        <div className="hr-top clearfix hr-shadow">
          <Navbar current={this.state.current} />
          <Breadcrumb />
        </div>
        <div className="content_wrap">
          <Gallery photos={photos} />
        </div>
      </React.Fragment>
    );
  }
}

export default Galeria;
