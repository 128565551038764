import React from "react";

class Breadcrumb extends React.Component {
  render() {
    return "";
    // <div class="top_panel_title top_panel_style_2 title_present breadcrumbs_present scheme_original">
    //   <div class="top_panel_title_inner top_panel_inner_style_2 title_present_inner breadcrumbs_present_inner">
    //     <div class="content_wrap">
    //       <h1 class="page_title">Contactanos</h1>
    //       <div class="breadcrumbs">
    //         <a class="breadcrumbs_item home" href="index.html">
    //           Portada
    //         </a>
    //         <span class="breadcrumbs_delimiter"></span>
    //         <span class="breadcrumbs_item current">Contactanos</span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  }
}

export default Breadcrumb;
