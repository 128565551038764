import React from "react";

import Header from "../../Skin/Denti/Header";
import Footer from "../../Skin/Denti/Footer";

function Layout(props) {
  return (
    <React.Fragment>
      <Header />
      {props.children}
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
