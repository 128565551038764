import React from "react";
//import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Navbar from "../Skin/Denti/Navbar";
import whatsapp from "../images/whatsapp-logo-1.png";
import facebook from "../images/facebook.png";

import GoogleMaps from "../components/GoogleMaps";

class Contacto extends React.Component {
  state = {
    current: "Contacto",
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>DentalPuente | Contacto</title>
        </Helmet>

        <div className="hr-top clearfix hr-shadow">
          <Navbar current={this.state.current} />
        </div>
        <div className="content_wrap">
          <div className="pbot352">
            <div className="row">
              <div className="col-8">
                <div className="row">
                  <div className="col-2">
                    <img
                      src={whatsapp}
                      alt="Contacto Whatsapp"
                      className="whatsapp_logo"
                    />
                  </div>
                  <div className="col-10">
                    <div className="dentistContact">
                      <p>¿Tienes preguntas?</p>
                      <h3 className="sc_title">Contactanos por WhatsApp!</h3>

                      <div>
                        <div className="wIcon">
                          <div>
                            <h4>Resolvemos tus dudas</h4>
                            <span>
                              Si tienes preguntas, contactanos! resolveremos
                              todas tus dudas para que asistas a consulta con
                              tranquilidad!
                            </span>
                          </div>
                        </div>
                        <div className="wIcon">
                          <div className="missionContainer">
                            <h4>Agenda tu cita</h4>
                            <span>
                              ¿Ya te decidiste? Agenda tu cita! utiliza el
                              numero de whatsapp: +523334758191
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      href="https://api.whatsapp.com/send?phone=523334758191&text=Hola%20buenas%20tardes,%20me%20gustaria%20agendar%20una%20cita%20para%20su%20consultorio%20personal%20en%20Teodomiro%20Manzano%204365-B"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary moreAbout"
                    >
                      Solicitar cita
                    </a>
                  </div>
                </div>
                <div className="hr-middle pbot2"></div>

                <div className="row">
                  <div className="col-2">
                    <img
                      src={facebook}
                      alt="Facebook page"
                      className="whatsapp_logo"
                    />
                  </div>
                  <div className="col-10">
                    <div className="dentistContact">
                      <h3 className="sc_title">Siguenos en Facebook!</h3>

                      <div>
                        <div className="wIcon">
                          <div>
                            <span>Comparte tu experiencia con nosotros</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      href="https://www.facebook.com/DentalPuente-2072424749721597"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary moreAbout"
                    >
                      Ver pagina de Facebook
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <GoogleMaps style_height="100%" style_width="100%" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contacto;
