import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faMobile,
  faAddressBook,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";

import HomeImage from "../images/Depositphotos_99045402_xl-2015.jpg";
import ortodoncia from "../images/ortodoncia.png";
import operatoria from "../images/operatoria.png";
import periodoncia from "../images/periodoncia.png";
import exodoncia from "../images/exodoncia.png";
import protesis from "../images/protesis.png";
import endodoncia from "../images/endodoncia.png";
import mission from "../images/mission.png";
import professional from "../images/professional.png";
import blanqueamiento from "../images/blanqueamiento.png";
import estetica from "../images/estetica.png";
import proteccion from "../images/proteccion.png";

import Navbar from "../Skin/Denti/Navbar";
import GoogleMaps from "../components/GoogleMaps";

class Home extends React.Component {
  state = {
    current: "Portada",
  };

  render() {
    const address_icon = <FontAwesomeIcon icon={faAddressBook} />;
    const mobile_icon = <FontAwesomeIcon icon={faMobile} />;
    const location_icon = <FontAwesomeIcon icon={faLocationArrow} />;
    return (
      <React.Fragment>
        <Helmet>
          <title>DentalPuente | Portada</title>
        </Helmet>

        <div className="hr-top clearfix hr-shadow">
          <Navbar current={this.state.current} />
        </div>

        <div className="content_wrap pbot352">
          <div className="pbot2">
            <h2 className="sc_title">Bienvenidos a DentalPuente</h2>
            <h6>Nos esforzamos día a día por brindarte el mejor servicio</h6>
          </div>
          <div className="row">
            <div className="col-6">
              <img src={HomeImage} alt="Info" className="ourMission_photo" />
            </div>
            <div className="col-6 dentistInfo ourMission">
              <div className="dentistContact">
                <h3 className="sc_title">
                  Nuestra prioridad es ayudarte a mejorar tu sonrisa
                </h3>

                <div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <img src={mission} alt="Nuestra mision" />
                    </div>
                    <div className="missionContainer">
                      <h4>Nuestra Misión</h4>
                      <span>
                        Otorgar servicios de calidad para el cuidado dental,
                        comprometidos con la ética profesional y enfocados a la
                        satisfacción de nuestros pacientes.
                      </span>
                    </div>
                  </div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <img src={professional} alt="Servicio profesional" />
                    </div>
                    <div>
                      <h4>Servicio Profesional</h4>
                      <span>Manteniendo altos estándares de calidad</span>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/About" className="btn btn-primary moreAbout">
                Más sobre nosotros
              </Link>
            </div>
          </div>
        </div>

        <div className="hr-top pbot352"></div>

        <div className="content_wrap">
          <div className="sc_services_wrap">
            <div className="sc_services">
              <h2 className="sc_title">Servicios Dentales</h2>
              <h6 className="pbot2">Te ofrecemos los siguientes servicios</h6>
              <div className="sc_columns">
                <div className="column-1_3 column_padding_bottom">
                  <div className="sc_services_item sc_services_item_1">
                    <img src={ortodoncia} alt="Ortodoncia" />
                    <div className="sc_services_item_content">
                      <h4 className="sc_services_item_title">Ortodoncia</h4>
                      <div className="sc_services_item_description">
                        <p>
                          Tratamientos de brackets esteticos o brackets
                          metalicos para una sonrisa perfecta.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column-1_3 column_padding_bottom">
                  <div className="sc_services_item sc_services_item_2">
                    <img src={operatoria} alt="Operatoria" />
                    <div className="sc_services_item_content">
                      <h4 className="sc_services_item_title">Operatoria</h4>
                      <div className="sc_services_item_description">
                        <p>
                          Obturaciones con amalgama dental o empastes compuestos
                          de resina para eliminar caries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column-1_3 column_padding_bottom">
                  <div className="sc_services_item sc_services_item_3">
                    <img src={periodoncia} alt="Periodoncia" />
                    <div className="sc_services_item_content">
                      <h4 className="sc_services_item_title">Periodoncia</h4>
                      <div className="sc_services_item_description">
                        <p>
                          Limpieza y pulido dental para mantener sus dientes y
                          encia saludables.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column-1_3 column_padding_bottom">
                  <div className="sc_services_item sc_services_item_4">
                    <img src={exodoncia} alt="Exodoncia" />
                    <div className="sc_services_item_content">
                      <h4 className="sc_services_item_title">Exodoncia</h4>
                      <div className="sc_services_item_description">
                        <p>
                          Extracción de dientes que no pueden ser tratados a
                          través de metodos conservativos.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column-1_3 column_padding_bottom">
                  <div className="sc_services_item sc_services_item_5">
                    <img src={protesis} alt="Protesis" />
                    <div className="sc_services_item_content">
                      <h4 className="sc_services_item_title">Protesis</h4>
                      <div className="sc_services_item_description">
                        <p>
                          Placas, puentes fijos y removibles, coronas metalicas
                          y porcelana.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column-1_3 column_padding_bottom">
                  <div className="sc_services_item sc_services_item_6">
                    <img src={endodoncia} alt="Endodoncia" />
                    <div className="sc_services_item_content">
                      <h4 className="sc_services_item_title">Endodoncia</h4>
                      <div className="sc_services_item_description">
                        <p>
                          Tratamiento para prolongar la permanencia de molares y
                          dientes anteriores en su boca.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sc_columns">
          <div className="col-4 col-cards">
            <div className="icon-cards bgc_1">
              <div className="dFlex">
                <div>
                  <img src={proteccion} alt="Protección Dental" />
                </div>
                <div className="cardDetails">
                  <h4>Protección Dental</h4>
                  <p className="icon-cards-description">
                    Metodos y tratamientos para prevenir caries y problemas de
                    encias, desde aplicación de fluor hasta selladores.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 col-cards">
            <div className="icon-cards bgc_2">
              <div className="dFlex">
                <div>
                  <img src={blanqueamiento} alt="Blanqueamiento Dental" />
                </div>
                <div className="cardDetails">
                  <h4>Blanqueamiento Dental</h4>
                  <p className="icon-cards-description">
                    Metodos de blanqueamiento utilizan peróxido de carbamida el
                    cual reacciona con el agua para formar peróxido de
                    hidrógeno.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 col-cards">
            <div className="icon-cards bgc_3">
              <div className="dFlex">
                <div>
                  <img src={estetica} alt="Estetica Dental" />
                </div>
                <div className="cardDetails">
                  <h4>Estetica Dental</h4>
                  <p className="icon-cards-description">
                    Solo existen 2 especialidades dentales que se enfocan en la
                    estetica dental: Prostodoncia y Ortodoncia.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pbot2">
          <div className="sc_columns pbot2">
            <div className="col-8 photo_top mapHolder">
              <GoogleMaps style_height="400px" style_width="100%" />
            </div>
            <div className="col-4 dentistInfo contactLocation">
              <h3>Datos de Contacto y Ubicación</h3>
              <div className="dentistContact">
                <p>Agenda una cita con nosotros, no dudes en llamar!</p>

                <div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <span className="icon iconHex">{address_icon}</span>
                    </div>
                    <div>
                      <h4>Dirección</h4>
                      <span>Teodomiro Manzano 4365, Guadalajara, Jal.</span>
                    </div>
                  </div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <span className="icon iconHex">{mobile_icon}</span>
                    </div>
                    <div>
                      <h4>Teléfono</h4>
                      <span>Citas 33 34 75 81 91</span>
                    </div>
                  </div>
                  <div className="wIcon">
                    <div className="iconContainer">
                      <span className="icon iconHex">{location_icon}</span>
                    </div>
                    <div>
                      <h4>Horarios</h4>
                      <span>Lun - Sab: 10:00am - 2:00pm Dom: Cerrado</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
