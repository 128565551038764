import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Layout from "../Skin/Denti/Layout";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import About from "../pages/About";
import Galeria from "../pages/Galeria";
import Contacto from "../pages/Contacto";
import Account from "../pages/Account";

//   <Route exact path="/badges" component={Badges} />
//   <Route exact path="/badges/new" component={BadgeNew} />
function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/About" component={About} />
          <Route exact path="/Galeria" component={Galeria} />
          <Route exact path="/Contacto" component={Contacto} />
          <Route exact path="/Account" component={Account} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
