import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../Skin/Denti/Navbar";
import Breadcrumb from "../components/Breadcrumb";

class Account extends React.Component {
  state = {
    current: "Account",
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>DentalPuente | Tu Cuenta</title>
        </Helmet>

        <div className="hr-top clearfix hr-shadow">
          <Navbar current={this.state.current} />
          <Breadcrumb />
        </div>
        <div className="content_wrap pbot352">
          <div className="content_wrap">
            <form method="POST" action="/switch">
              <input type="hidden" name="ac" value="login" />
              <div class="form-group">
                <label for="exampleInputEmail1">Tu direccion de email</label>
                <input
                  type="email"
                  class="form-control"
                  id="emailInput"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  name="emailInput"
                />
                <small id="emailHelp" class="form-text text-muted">
                  Tus datos de acceso a tu cuenta - Utilizamos tu cuenta de
                  correo como identificador unico.
                </small>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">
                  Tu contraseña de acceso
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="passwordInput"
                  placeholder="Contraseña"
                  name="passwordInput"
                />
              </div>

              <button type="submit" class="btn btn-primary">
                Conectar
              </button>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Account;
